@import 'custom/vars';

@media (max-width: 1369px) {
  .section {
    $section: &;
    &--01 {
      .swiper {
        $swiper: &;
        &-controls {
          display: none;
        }
      }
    }
    &--02 {
      height: auto;
      min-height: auto;
      .text {
        margin: 17px 0 0 0;
      }
    }
    &--03 {
      height: auto;
      padding: 33px 0 23px 0;
      #{$section}__inner {
        height: 100%;
      }
      .swiper {
        $swiper: &;
        &-container {
          min-height: 550px; //0421추가
        }
        &-wrap {
          width: 100%;
          height: auto;
          margin: 0;
          //0421삭제 padding: 0 0 40px 0;
        }
        &-slide {
          padding: 0 20px;
        }
        &-controls {
          @at-root #{$swiper}-button-prev {
            display: none;
          }
          @at-root #{$swiper}-button-next {
            display: none;
          }
        }
      }
    }
    &--04 {
      min-height: auto;
      padding: 0 0 52px 0;
      .swiper {
        $swiper: &;
        &-container {
          min-height: 240px; //0421추가
        }
        &-wrap {
          width: 100%;
          margin: 0;
          padding: 0 0 45px 0;
        }
        &-slide {
          padding: 0 20px;
        }
        &-pagination {
          display: block;
          width: 100%;
          bottom: 0;
          left: 0;
          &-bullet {
            background-color: $black;
            opacity: 0.2;
            &-active {
              opacity: 0.4;
            }
            & ~ span {
              margin: 0 0 0 8px;
            }
          }
        }
      }
      .notice {
        margin: 30px 0 0 0;
        /*0421추가*/
        height: 150px;
      }
    }
    .title {
      padding: 60px 0 0 0;
    }
  }

  .swiper {
    &-button-prev {
      display: none;
    }
    &-button-next {
      display: none;
    }
    &-pagination {
      display: block;
      width: 100%;
      bottom: 0;
      left: 0;
      &-bullet {
        background-color: $white;
        opacity: 0.5;
        &-active {
          opacity: 1;
        }
        & ~ span {
          margin: 0 0 0 8px;
        }
      }
    }
  }

  .figure {
    &__img {
      position: relative;
      padding-top: 125%;
      img {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    &__caption {
      top: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      padding: 0;
      text-align: center;
    }
    &__title {
      font-size: 26px;
      font-weight: 200;
      font-style: normal;
      letter-spacing: -0.65px;
    }
    &__text {
      font-size: 14px;
      font-weight: 200;
      font-style: normal;
      letter-spacing: -0.35px;
      line-height: 24px;
    }
  }

  .booking-module {
    width: 100%;
    height: 500px; //0421수정 auto
    padding: 19px 20px 16px 20px;
    box-shadow: 0 2px 12px rgba(40, 40, 40, 0.5);
    border-radius: 10px;
    //0421추가
    overflow: hidden;
    &__head {
    }
    &__title {
      font-size: 16px;
      font-style: normal;
      letter-spacing: -0.4px;
    }
    &__date {
      margin: 0;
      font-size: 10px;
    }
    &__state {
      top: 31px;
      font-size: 10px;
      font-weight: 400;
      &::before {
        width: 19px;
        height: 6px;
      }
    }
    &__count {
      margin: 14px 0 0 0;
      &--item {
        em {
          font-size: 13px;
        }
        .system {
          margin: 1px 0 0 0;
          font-size: 16px;
        }
      }
    }
    &__status {
      margin: 3px 0 0 0;
      justify-content: flex-start;
      &--item {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        min-width: 100%;
        max-width: 100%;
        height: auto;
        margin: 10px 0 0 0;
        padding: 9px 13px;
      }
      &--bar {
        min-width: 40%;
        max-width: 40%;
        height: 8px;
      }
      &--per {
        top: 16px;
        font-size: 12px;
      }
      &--caption {
        min-width: 60%;
        max-width: 60%;
        margin: 0;
      }
    }
  }

  .search {
    width: 100%;
    padding: 0 20px 58px 20px;
    &-wrap {
      flex-direction: column;
    }
    &__imo {
      opacity: 0.71;
    }
  }

  .notice {
    &__title {
    }
    &__text {
    }
  }
}
