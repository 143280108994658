/**
 * MUI Helpers module
 */

// ============================================================================
// ANIMATION
// ============================================================================

.mui--no-transition {
  transition: none !important;
}

// ============================================================================
// USER SELECT
// ============================================================================
.mui--no-user-select {
  user-select: none;
}

// ============================================================================
// CARET
// ============================================================================

.mui-caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: $mui-caret-width solid;
  border-right: $mui-caret-width solid transparent;
  border-left: $mui-caret-width solid transparent;
}

// ============================================================================
// ALIGNMENT HELPERS
// ============================================================================

.mui--text-left {
  text-align: left !important;
}
.mui--text-right {
  text-align: right !important;
}
.mui--text-center {
  text-align: center !important;
}
.mui--text-justify {
  text-align: justify !important;
}
.mui--text-nowrap {
  white-space: nowrap !important;
}

.mui--align-baseline {
  vertical-align: baseline !important;
}
.mui--align-top {
  vertical-align: top !important;
}
.mui--align-middle {
  vertical-align: middle !important;
}
.mui--align-bottom {
  vertical-align: bottom !important;
}

// ============================================================================
// TEXT COLOR HELPERS
// ============================================================================

.mui--text-dark {
  color: $mui-text-dark;
}
.mui--text-dark-secondary {
  color: $mui-text-dark-secondary;
}
.mui--text-dark-hint {
  color: $mui-text-dark-hint;
}

.mui--text-light {
  color: $mui-text-light;
}
.mui--text-light-secondary {
  color: $mui-text-light-secondary;
}
.mui--text-light-hint {
  color: $mui-text-light-hint;
}

.mui--text-accent {
  color: $mui-text-accent;
}
.mui--text-accent-secondary {
  color: $mui-text-accent-secondary;
}
.mui--text-accent-hint {
  color: $mui-text-accent-hint;
}

.mui--text-black {
  color: mui-color('black');
}
.mui--text-white {
  color: mui-color('white');
}
.mui--text-danger {
  color: $mui-text-danger;
}

// ============================================================================
// BACKGROUND COLORS
// ============================================================================

.mui--bg-primary {
  background-color: $mui-primary-color;
}
.mui--bg-primary-dark {
  background-color: $mui-primary-color-dark;
}
.mui--bg-primary-light {
  background-color: $mui-primary-color-light;
}

.mui--bg-accent {
  background-color: $mui-accent-color;
}
.mui--bg-accent-dark {
  background-color: $mui-accent-color-dark;
}
.mui--bg-accent-light {
  background-color: $mui-accent-color-light;
}

.mui--bg-danger {
  background-color: $mui-danger-color;
}

// ============================================================================
// LISTS
// ============================================================================

@mixin x-list-unstyled {
  padding-left: 0;
  list-style: none;
}

// [converter] extracted as `@mixin list-unstyled` for libsass compatibility
.mui-list--unstyled {
  @include x-list-unstyled();
}

// Inline turns list items into inline-block
.mui-list--inline {
  @include x-list-unstyled();

  margin-left: -5px;

  > li {
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px;
  }
}

// ============================================================================
// DEPTH HELPERS
// ============================================================================

.mui--z1 {
  box-shadow: 0 1px 3px rgba(mui-color('black'), 0.12),
    0 1px 2px rgba(mui-color('black'), 0.24);
}

.mui--z2 {
  box-shadow: 0 3px 6px rgba(mui-color('black'), 0.16),
    0 3px 6px rgba(mui-color('black'), 0.23);
}

.mui--z3 {
  box-shadow: 0 10px 20px rgba(mui-color('black'), 0.19),
    0 6px 6px rgba(mui-color('black'), 0.23);
}

.mui--z4 {
  box-shadow: 0 14px 28px rgba(mui-color('black'), 0.25),
    0 10px 10px rgba(mui-color('black'), 0.22);
}

.mui--z5 {
  box-shadow: 0 19px 38px rgba(mui-color('black'), 0.3),
    0 15px 12px rgba(mui-color('black'), 0.22);
}

// ============================================================================
// FLOAT HELPERS
// ============================================================================

.mui--clearfix {
  @include mui-clearfix();
}

.mui--pull-right {
  float: right !important;
}

.mui--pull-left {
  float: left !important;
}

// ============================================================================
// TOGGLE HELPERS
// ============================================================================

.mui--hide {
  display: none !important;
}

.mui--show {
  display: block !important;
}

.mui--invisible {
  visibility: hidden;
}

.mui--overflow-hidden {
  overflow: hidden !important;
}

.mui--overflow-hidden-x {
  overflow-x: hidden !important;
}

.mui--overflow-hidden-y {
  overflow-y: hidden !important;
}

// ============================================================================
// RESPONSIVE UTILITIES
// ============================================================================

@mixin x-responsive-visibility($parent) {
  #{$parent} {
    display: block !important;
  }

  table#{$parent} {
    display: table;
  }
  tr#{$parent} {
    display: table-row !important;
  }
  th#{$parent},
  td#{$parent} {
    display: table-cell !important;
  }
}

// [converter] $parent hack
@mixin x-responsive-invisibility($parent) {
  #{$parent} {
    display: none !important;
  }
}

.mui--visible-xs-block,
.mui--visible-xs-inline,
.mui--visible-xs-inline-block,
.mui--visible-sm-block,
.mui--visible-sm-inline,
.mui--visible-sm-inline-block,
.mui--visible-md-block,
.mui--visible-md-inline,
.mui--visible-md-inline-block,
.mui--visible-lg-block,
.mui--visible-lg-inline,
.mui--visible-lg-inline-block,
.mui--visible-xl-block,
.mui--visible-xl-inline,
.mui--visible-xl-inline-block {
  display: none !important;
}

@media (max-width: $mui-screen-xs-max) {
  @include x-responsive-visibility('.mui-visible-xs');

  .mui--visible-xs-block {
    display: block !important;
  }
  .mui--visible-xs-inline {
    display: inline !important;
  }
  .mui--visible-xs-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: $mui-screen-sm-min) and (max-width: $mui-screen-sm-max) {
  @include x-responsive-visibility('.mui-visible-sm');

  .mui--visible-sm-block {
    display: block !important;
  }
  .mui--visible-sm-inline {
    display: inline !important;
  }
  .mui--visible-sm-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: $mui-screen-md-min) and (max-width: $mui-screen-md-max) {
  @include x-responsive-visibility('.mui-visible-md');

  .mui--visible-md-block {
    display: block !important;
  }
  .mui--visible-md-inline {
    display: inline !important;
  }
  .mui--visible-md-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: $mui-screen-lg-min) and (max-width: $mui-screen-lg-max) {
  @include x-responsive-visibility('.mui-visible-lg');

  .mui--visible-lg-block {
    display: block !important;
  }
  .mui--visible-lg-inline {
    display: inline !important;
  }
  .mui--visible-lg-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: $mui-screen-xl-min) {
  @include x-responsive-visibility('.mui-visible-xl');

  .mui--visible-xl-block {
    display: block !important;
  }
  .mui--visible-xl-inline {
    display: inline !important;
  }
  .mui--visible-xl-inline-block {
    display: inline-block !important;
  }
}

@media (max-width: $mui-screen-xs-max) {
  @include x-responsive-invisibility('.mui--hidden-xs');
}

@media (min-width: $mui-screen-sm-min) and (max-width: $mui-screen-sm-max) {
  @include x-responsive-invisibility('.mui--hidden-sm');
}

@media (min-width: $mui-screen-md-min) and (max-width: $mui-screen-md-max) {
  @include x-responsive-invisibility('.mui--hidden-md');
}

@media (min-width: $mui-screen-lg-min) and (max-width: $mui-screen-lg-max) {
  @include x-responsive-invisibility('.mui--hidden-lg');
}

@media (min-width: $mui-screen-xl-min) {
  @include x-responsive-invisibility('.mui--hidden-xl');
}

// ============================================================================
// SCROLL LOCK
// ============================================================================

.mui-scrlock--showbar-y {
  overflow-y: scroll !important;
}

.mui-scrlock--showbar-x {
  overflow-x: scroll !important;
}
