@import 'custom/vars';

.section {
  $section: &;
  &--01 {
    .swiper {
      $swiper: &;
      &-controls {
        position: absolute;
        top: 330px;
        left: calc(50% + 110px);
        width: 50%;
        height: 32px;
        @at-root #{$swiper}-button-prev {
          left: 0;
        }
        @at-root #{$swiper}-button-next {
          right: auto;
          left: 40px;
        }
      }
    }
  }
  &--02 {
    height: 300px;
    background-color: #f5881f;
    color: $white;
    text-align: center;
    #{$section}__inner {
      height: 100%;
    }
    .text {
      margin: 17px 0 0 0;
    }
  }
  &--03 {
    height: 640px;
    background: url('../img/bg_section03.jpg') no-repeat 50% 50%;
    background-size: cover;
    #{$section}__inner {
      height: 100%;
    }
    .swiper {
      $swiper: &;
      &-wrap {
        display: flex;
        align-items: center;
        width: 1020px;
        height: 100%;
        margin: 0 auto;
      }
      &-controls {
        @at-root #{$swiper}-button-prev {
          outline: none;
          left: 50%;
          transform: translate(-602px, 0);
        }
        @at-root #{$swiper}-button-next {
          outline: none;
          right: 50%;
          transform: translate(602px, 0);
        }
      }
    }
  }
  &--04 {
    min-height: 300px;
    padding: 0 0 90px 0;
    text-align: center;
    background-color: $white;
    .swiper {
      $swiper: &;
      &-wrap {
        width: 1020px;
        margin: 0 auto;
      }
      &-controls {
        @at-root #{$swiper}-button-prev {
          left: -96px;
          background-image: url('../img/ico_swiper_prev_black.png');
        }
        @at-root #{$swiper}-button-next {
          right: -96px;
          background-image: url('../img/ico_swiper_next_black.png');
        }
      }
    }
    .notice {
      margin: 50px 0 0 0;
    }
  }
  .title {
    padding: 74px 0 0 0;
    font-size: 30px;
    font-weight: 200;
    font-style: normal;
    letter-spacing: -0.75px;
    font-family: 'NotoSansCJK';
  }
  .text {
    font-size: 16px;
    letter-spacing: -0.4px;
  }
}

.swiper {
  &-wrap {
    position: relative;
  }
  &-button-prev,
  &-button-next {
    width: 32px !important;
    height: 32px !important;
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: 100%;
    outline: none;
    &:after {
      display: none;
    }
  }
  &-button-prev {
    background-image: url('../img/ico_swiper_prev.png');
  }
  &-button-next {
    background-image: url('../img/ico_swiper_next.png');
  }
  &-pagination {
    display: none;
  }
}

.figure {
  position: relative;
  &__img {
    max-height: 520px;
    font-size: 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__caption {
    position: absolute;
    top: 140px;
    left: 50%;
    width: 50%;
    padding: 0 0 0 110px;
    box-sizing: border-box;
    color: $white;
  }
  &__title {
    font-size: 30px;
    font-weight: 300;
    font-style: normal;
    font-family: 'NotoSansCJK';
    letter-spacing: -0.75px;
  }
  &__text {
    margin: 15px 0 0 0;
    font-size: 20px;
    font-family: 'NotoSansCJK';
    letter-spacing: -0.5px;
    line-height: 32px;
    word-break: keep-all; //0423추가
  }
}

.booking-module {
  $bookingModule: &;
  width: 474px;
  height: 486px;
  padding: 30px 40px 0 40px;
  border-radius: 20px;
  box-sizing: border-box;
  background-color: $white;
  &__head {
    position: relative;
  }
  &__title {
    font-size: 20px;
    font-weight: bold;
    font-style: normal;
    font-family: 'NotoSansCJK';
    letter-spacing: -0.5px;
  }
  &__date {
    margin: 5px 0 0 0;
    font-size: 14px;
    color: #8e8e8e;
  }
  &__state {
    position: absolute;
    top: 36px;
    right: 0;
    color: #484848;
    font-size: 13px;
    &::before {
      display: none;
      content: '';
      width: 32px;
      height: 10px;
      border-radius: 7px;
      margin: 3px 6px 0 0;
      vertical-align: top;
    }
    &.is-want {
      &::before {
        display: inline-block;
        background-color: #2dd22d;
      }
    }
    &.is-difficult {
      &::before {
        display: inline-block;
        background-color: orange;
      }
    }
    &.is-danger {
      &::before {
        display: inline-block;
        background-color: red;
      }
    }
  }
  &__count {
    display: flex;
    justify-content: space-between;
    margin: 27px 0 0 0;
    &--item {
      flex: 1;
      em {
        font-size: 14px;
        font-weight: 400;
        font-style: normal;
        color: #8e8e8e;
      }
      .system {
        display: block;
        margin: 6px 0 0 0;
        font-size: 20px;
        font-weight: 500;
        font-style: normal;
        color: #024287;
      }
    }
  }
  &__status {
    margin: 9px 0 0 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    &--item {
      min-width: 190px;
      max-width: 190px;
      height: 80px;
      margin: 14px 0 0 0;
      padding: 20px 14px 12px 14px;
      border-radius: 6px;
      border: 1px solid #efefef;
      box-sizing: border-box;
    }
    &--bar {
      position: relative;
      display: block;
      height: 10px;
      border-radius: 6px;
      background-color: #d3d3d3;
    }
    &--per {
      position: absolute;
      top: 18px; //0423수정 25px;
      right: 0;
      font-size: 16px;
      text-transform: uppercase;
    }
    &--system {
      position: relative;
      display: block;
      height: 100%;
      border-radius: 6px;
      &:after {
        position: absolute;
        left: 0;
        top: 0;
        content: '';
        display: block;
        height: 100%;
        width: 100%;
        border-radius: 6px;
        animation: show-bar-one 2s 0.2s forwards;
        background-image: linear-gradient(270deg, #bc1905 0%, #ff8400 100%);
      }
      @-webkit-keyframes show-bar-one {
        0% {
          width: 0;
        }
        100% {
          width: 100%;
        }
      }
    }
    &--caption {
      margin: 10px 0 0 0;
      font-size: 11px;
      color: #484848;
    }
    &--subject {
      display: block;
      font-weight: 500;
      font-style: normal;
    }
    &--text {
      display: block;
      margin: 2px 0 0 0;
    }
  }
}

.search {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
  .title {
    padding: 0;
  }
  &.is-left {
    background: #f5881f;
  }
  &.is-right {
    background: #cf493f;
  }
  &-wrap {
    display: flex;
    height: 100%;
  }
  &-value {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0 0 0;
    padding: 0 14px;
    min-height: 70px;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -1px;
    &--fail {
      background: rgba($color: #c80d02, $alpha: 0.3);
    }
    &--result {
      background: rgba($color: #3d3d3d, $alpha: 0.3);
    }
    &__list {
      width: 100%;
      text-align: left;
      font-family: 'NotoSansCJK';
      letter-spacing: 0;
      strong {
        display: inline-block;
        font-weight: normal;
        min-width: 60px;
      }
      .system {
        color: #fff;
      }
    }
  }
  &__box {
    position: relative;
    width: 210px;
    height: 36px;
    margin: 16px auto 0 auto;
    padding: 0 0 0 56px;
    border-radius: 4px;
    border: 1px solid $white;
    input {
      width: 100%;
      height: 100%;
      border: none;
      background: none;
      text-align: left;
    }
    &--input {
      outline: none;
      font-size: 16px;
      font-weight: 400;
      font-style: normal;
      letter-spacing: 0.15px;
      line-height: 24px;
      color: $white;
      &::-webkit-input-placeholder {
        opacity: 0.5;
        color: $white;
      }
      &::placeholder {
        opacity: 0.5;
        color: $white;
      }
    }
    &--submit {
      position: absolute;
      top: 0;
      left: 16px;
      width: 24px;
      height: 100%;
      border: none;
      outline: none;
      background: url('../img/ico_search.png') no-repeat 50% 50%;
      background-size: 18px;
    }
  }
  &__imo {
    color: $white;
  }
}

.notice {
  text-align: left;
  /*0421추가*/
  display: flex;
  flex-direction: column;
  min-height: 170px;
  justify-content: space-between;
  cursor: pointer;

  &__title {
    font-size: 20px; //0615수정 2rem;
    font-weight: 400;
    font-style: normal;
    letter-spacing: -0.5px;
    overflow: hidden;
    text-overflow: ellipsis;

    display: -webkit-box;
    -webkit-line-clamp: 2; //라인수
    -webkit-box-orient: vertical;
    //word-wrap: break-word; //0423삭제

    /*0422추가*/
    padding: 3px 3px;
    line-height: 1.3;
    height: 2.6em;
  }
  &__text {
    overflow: hidden;
    //0421 수정 margin: 36px 0 0 0;
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: -0.35px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5; /* 라인수 */
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    /*0422추가*/
    height: 105px; //0615수정 100px
    padding: 3px 3px;
  }
}
