@import 'custom/vars';

// layout
html,
body {
  height: 100%;
  margin: 0;
}
.app {
  position: relative;
  min-width: 1280px;
  min-height: 100%;
}
.app-header {
  $header: &;
  z-index: 1000;
  position: fixed;
  // top: 0;
  // left: 0;
  overflow: hidden;
  width: 100%;
  height: 80px;
  background: $white;
  transition: all 0.5s ease-in-out;
  @media (min-width: 1368px) {
    .is-active,
    &:hover {
      &.is-gnb-over {
        height: 410px; /*0416수정210px;*/
        // height: 390px; /*0416수정210px;*/
        &:after {
          position: absolute;
          top: 80px;
          left: 0;
          display: block;
          content: '';
          width: 100%;
          height: 420px; /*0416수정130px;*/
          // height: 410px; /*0416수정130px;*/
          background: #f6f6f6;
        }
        .gnb {
          &__depth {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
    &__utill ul {
      //   display: flex;
      //   position: absolute;
      //   right: 15px;
      top: 0;
      //   z-index: 30;
      //   // width: 180px;
      //   width: 193px;
      border-left: 1px solid #ededed;
      //   // padding: 0 0 0 20px;
      //   padding: 0 0 0 10px;
      //   height: 80px;
    }
  }
  &__inner {
    z-index: 1;
    height: 100%;
    @include pcInner();
  }
  &__logo {
    position: absolute;
    top: 15px;
    left: 20px;
    display: block;
    &--link {
      display: inline-block;
      // width: 95px;
      // height: 39px;
      width: 160px;
      height: 55px;
      background: url('../img/logo.png') no-repeat 50% 50%;
      background-size: 100%;
    }
  }
  &__utill {
    $utill: &;

    // position: absolute;
    // top: 0;
    // right: 30px;
    // height: 80px;
    // padding: 0 0 0 32px;
    // border-left: 1px solid #ededed;
    // /*0421추가*/
    // display: flex;
    // flex-direction: column;
    // justify-content: center;

    ul {
      display: flex;
      /*0421수정 
      align-items: center;
      height: 100%;*/
      /*0422추가*/
      // justify-content: flex-end;
      // padding-right: 3px;
      position: absolute;
      // right: 15px; 수정0823
      // top: 0;
      z-index: 30;
      // width: 180px;
      // width: 193px;수정0823
      // border-left: 1px solid #ededed;
      // padding: 0 0 0 20px;
      padding: 0 0 0 10px;
      height: 80px;

      // right: 0px;
      right: 15px;
      // width: 225px;
      width: 185px;
      justify-content: flex-end;
    }
    &--item {
      line-height: 50px;
      height: 40px;
      & ~ #{$utill}--item {
        // margin: 0 0 0 12px;
        padding-left: 5px;
      }
    }
    &--item2 {
      flex: 1;
      line-height: 70px;
      padding-left: 5px;
      & ~ #{$utill}--item {
        padding: 0 0 0 12px;
      }
    }
    &--anchor {
      font-size: 11px;
      font-weight: 600;
      &.is-anchor-login {
        padding: 0 0 0 29px;
        font-weight: 700;
        background: url('../img/ico_utill_login.png') no-repeat 0 50%;
        background-size: 19px;
        cursor: pointer;
        vertical-align: middle;
      }
    }
    &__isUser {
      // text-align: end;
      // margin-top: 3px;
      // font-weight: 400;
      // line-height: 97px;
      // text-align: end;
      margin-top: 3px;
      font-weight: 400;
      // right: 15px;
      right: 9px;
      position: absolute;
      font-size: 12px;
      height: 40px;
      top: 40px;
      z-index: 30;
      max-width: 239px;
    }
  }
}
.gnb {
  $gnb: &;
  // height: 160px; //0416수정80px;
  // /*0416추가*/
  // display: flex;
  // justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 30;
  // padding: 0 210px 0px 125px;
  // padding: 0px 240px 0px 130px;
  padding: 0px 200px 0px 125px;

  width: 100%;
  box-sizing: border-box;
  &__container {
    // display: flex;
    // // justify-content: space-between; /* 0416수정center;*/
    // padding: 20px 0 0 0;
    // height: 100%;
    // // width: 900px; /*0419추가*/
    text-align: center;
    display: flex;
    justify-content: space-evenly;
    max-width: 1368px;
    margin: auto;
  }
  &__item {
    // position: relative;
    display: inline-block;
    position: relative;
    margin: 0 12px;
    width: 150px;

    .is-active,
    &:hover {
      #{$gnb}__anchor {
        color: #f5881f;
      }
    }
    &:hover a:after {
      left: 0;
      width: 100%;
    }
    /* 0416수정
    & ~ #{$gnb}__item {
      margin: 0 0 0 57px;
    }
    */
    // .is-active,
    // &:hover {
    //   #{$gnb}__anchor {
    //     color: #f5881f;
    //   }
    //   #{$gnb}__anchor:after {
    //     bottom: 0;
    //     opacity: 1;
    //   }
    // }
  }
  &__anchor {
    // position: relative;
    // display: block;
    // padding: 10px 8px 32px 8px;
    // font-size: 14px;
    // font-weight: 500;
    // font-style: normal;
    // letter-spacing: normal;
    // transition: all 0.3s ease;
    // /*0416추가*/
    // width: 140px;
    // text-align: center;
    display: block;
    position: relative;
    // color: blue;
    // width: 140px;
    height: 79px;
    line-height: 79px;
    font-size: 17px;
    font-weight: 500;
    padding: 0 28px;
    margin: 0 2px;
    &:after {
      // position: absolute;
      // bottom: -4px;
      // left: 0;
      // display: block;
      // opacity: 0;
      // content: '';
      // width: 100%;
      // height: 4px;
      // transition: all 0.3s ease;
      content: '';
      position: absolute;
      left: 50%;
      bottom: -1px;
      width: 0;
      height: 2px;
      background: #f5881f;
      transition: all 0.5s;
    }
  }
  &__depth {
    // opacity: 0;
    // visibility: hidden;
    // position: absolute;
    // top: 60px;
    // left: 50%; /*0419수정50% */
    // width: 140px;
    // height: 100%;
    // padding: 14px 0 34px 0;
    // text-align: center;
    // transform: translate(-49%, 0);
    // transform: opacity 0.5s ease-in-out visibility 0.5s ease-in-out;
    // will-change: transform;
    // &--item {
    //   margin: 0 0 10px 0;
    // }
    position: absolute;
    width: 100%;
    // padding: 20px 0 0;
    padding: 20px 2px 0;
    text-align: center;
    &--item {
      //nav>ul>li>ul>li
      // line-height: 28px;

      margin-bottom: 9px;
    }
    &--anchor {
      // font-size: 12px;
      // font-weight: 400;
      // font-size: 11.3px;
      // font-weight: 500;
      // font-style: normal;
      // letter-spacing: -0.33px;
      // color: #484848;
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      letter-spacing: -0.33px;
      // color: #484848;
      color: #8e8e8e;
      transition: all 0.5s;
      word-break: break-word;
      &:hover {
        // color: #bc1905;
        cursor: pointer;
        color: #000;
      }
    }
  }
}
.app-container {
  position: relative;
  padding: 80px 0 0 0;
}
.app-footer {
  padding: 0 0 70px 0;
  background: #efefef;
  &__inner {
    @include pcInner();
    @include clear();
  }
  a {
    padding: 0;
    font-weight: 500; //수정 400
    text-transform: inherit;
    border-radius: 0;
    //0416삭제display: list-item;
    &::marker {
      display: none;
    }
  }
  &__top {
    display: flex;
    justify-content: space-between;
  }
  .company {
    $company: &;
    &__name {
      display: block;
      // width: 95px;
      // height: 40px;
      // margin: 61px 0 0 0;
      // background: url('../img/logo_footer.png') no-repeat 0 50%;
      background: url('../img/logo.png') no-repeat 0 50%;
      width: 150px;
      height: 85px;
      margin: 50px 0 0 0;
      filter: grayscale(1);
      opacity: 50%;
      background-size: 100%;
    }
    &__info {
      &--item {
        &:first-child {
          // margin: 36px 10px 0 0;
          margin: 0px 10px 0 0;
        }
        margin: 2px 10px 0 0; /*0421수정 13px 0 0 0;*/
        font-size: 13px;
        font-weight: 400; /* 수정 500*/
        font-style: normal;
        letter-spacing: 0.2px;
        color: #8e8e8e;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
        /*0421수정
        a {
          color: #8e8e8e;
          &:hover {
            text-decoration: underline;
          }
        }*/
      }
    }
  }
  .site-map {
    $sitemap: &;
    margin: 136px 0 0 0;
    &__container {
      display: flex;
    }
    &__item {
      display: list-item;
      min-width: 140px;
    }
    &__subject {
      display: block;
      font-size: 14px;
      font-weight: 500;
    }
    &__depth {
      margin: 10px 0 0 0;
      &--item {
        display: block;
        margin: 10px 0 0 0;
      }
      &--anchor {
        color: #8e8e8e;
        font-size: 13px;
        letter-spacing: -0.33px;
      }
    }
  }

  &__bottom {
    display: flex;
    justify-content: space-between;
    margin: 35px 0 0 0;
    padding: 28px 0 0 0;
    border-top: 1px solid #d3d3d3;
  }
  &__copy {
    display: flex;
    &--subject {
      display: block;
      font-size: 20px;
      font-weight: 500;
      img {
        width: 156px;
      }
    }
    &--text {
      margin: 0; //0 0 0 13px;
      font-size: 13px;
      font-weight: 500;
      font-style: normal;
      letter-spacing: -0.33px;
    }
    &--terms {
      //--terms추가
      margin: 0 30px 0 13px;
      padding: 2px 0 0 0;
      font-size: 13px;
      font-weight: 400;
      font-style: normal;
      letter-spacing: -0.33px;
      &--text {
        padding: 1px 18px 0 0;
        display: inline-block;
        cursor: pointer;
      }
    }
  }
  &__call {
    display: flex;
    align-items: center;
    &--subject {
      padding: 1px 0 0 0;
      font-size: 13px;
      font-weight: bold;
      font-style: normal;
      letter-spacing: -0.33px;
    }
    &--number {
      margin: 0 0 0 12px;
      font-size: 20px;
      font-weight: 400;
      text-decoration: initial;
      &::marker {
        content: '';
      }
    }
  }
}

// ui
.is-desktop {
  display: block;
}
.is-mobile {
  display: none;
}
.is-desktop-wrapline {
  display: block !important;
}
.is-mobile-wrapline {
  display: none !important;
}
