@mixin form-label() {
  // Positioning
  position: absolute;
  top: 0;

  // Display
  display: block;
  width: 100%;

  // Other
  color: $mui-label-font-color;
  font-size: $mui-label-font-size;
  font-weight: 400;
  line-height: $xFormLabelLineHeight;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
