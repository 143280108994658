/**
 * MUI Table Component
 */

// Table style
.mui-table {
  width: 100%;
  max-width: 100%;
  margin-bottom: $mui-base-line-height-computed;

  // Cells
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th {
        text-align: left;
      }

      > th,
      > td {
        padding: $mui-table-cell-padding;
        line-height: $mui-base-line-height;
      }
    }
  }

  // Bottom align for column headings
  > thead > tr > th {
    border-bottom: 2px solid $mui-table-border-color;
    font-weight: 700;
  }

  // multiple tbody instances
  > tbody + tbody {
    border-top: 2px solid $mui-table-border-color;
  }

  // Bordered style
  &.mui-table--bordered > tbody > tr > td {
    border-bottom: 1px solid $mui-table-border-color;
  }
}
