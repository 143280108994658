/**
 * MUI Divider Component and CSS Helpers
 */

.mui-divider {
  display: block;
  height: 1px;
  background-color: $mui-divider-color;
}

.mui--divider-top {
  border-top: 1px solid $mui-divider-color;
}

.mui--divider-bottom {
  border-bottom: 1px solid $mui-divider-color;
}

.mui--divider-left {
  border-left: 1px solid $mui-divider-color;
}

.mui--divider-right {
  border-right: 1px solid $mui-divider-color;
}
