/**
 * MUI Grid module
 */

// row
.mui-row {
  @include mui-clearfix();

  margin-left: ($mui-grid-gutter-width / -2);
  margin-right: ($mui-grid-gutter-width / -2);
}

// columns
@include mui-make-grid-columns();

// extra small grid
@include mui-make-grid('xs');

// small grid
@media (min-width: $mui-screen-sm-min) {
  @include mui-make-grid('sm');
}

// medium grid
@media (min-width: $mui-screen-md-min) {
  @include mui-make-grid('md');
}

// large grid
@media (min-width: $mui-screen-lg-min) {
  @include mui-make-grid('lg');
}

// extra-large grid
@media (min-width: $mui-screen-xl-min) {
  @include mui-make-grid('xl');
}
