/**
 * MUI Typography module
 */

.mui--text-display4 {
  @include mui-text('display4');
}

.mui--text-display3 {
  @include mui-text('display3');
}

.mui--text-display2 {
  @include mui-text('display2');
}

.mui--text-display1 {
  @include mui-text('display1');
}

.mui--text-headline {
  @include mui-text('headline');
}

.mui--text-title {
  @include mui-text('title');
}

.mui--text-subhead {
  @include mui-text('subhead');
}

.mui--text-body2 {
  @include mui-text('body2');
}

.mui--text-body1 {
  @include mui-text('body1');
}

.mui--text-caption {
  @include mui-text('caption');
}

.mui--text-menu {
  @include mui-text('menu');
}

.mui--text-button {
  @include mui-text('button');
}
