body {
  overflow-x: auto;
  width: 100%
}

.container {
  width: 100%;
  min-width: 1340px;
  height: auto;
  box-sizing: initial;
  font-family:'Noto Sans KR', sans-serif;font-weight:400;font-size:14px;color:#666;-webkit-overflow-scrolling: touch;letter-spacing:-.5px !important;line-height:initial;
}
.container_servicePage {
  width: 100%;
  min-width: 1340px;
  height: auto;
  box-sizing: initial;
  font-family:'Noto Sans KR', sans-serif;
  /* font-weight:400;font-size:14px; */
  /* -webkit-overflow-scrolling: touch; */
  /* letter-spacing:-.5px !important; */
  /* line-height:initial; */
}

/* noto-sans-kr-100 - latin_korean */
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 100;
  src: url('../assetsNew/fonts/noto-sans-kr-v9-latin_korean-100.eot'); /* IE9 Compat Modes */
  src: local('Noto Sans KR Thin'), local('NotoSansKR-Thin'),
  url('../assetsNew/fonts/noto-sans-kr-v9-latin_korean-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../assetsNew/fonts/noto-sans-kr-v9-latin_korean-100.woff2') format('woff2'), /* Super Modern Browsers */
  url('../assetsNew/fonts/noto-sans-kr-v9-latin_korean-100.woff') format('woff'), /* Modern Browsers */
  url('../assetsNew/fonts/noto-sans-kr-v9-latin_korean-100.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../assetsNew/fonts/noto-sans-kr-v9-latin_korean-100.svg#NotoSansKR') format('svg'); /* Legacy iOS */
}
/* noto-sans-kr-300 - latin_korean */
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 300;
  src: url('../assetsNew/fonts/noto-sans-kr-v9-latin_korean-300.eot'); /* IE9 Compat Modes */
  src: local('Noto Sans KR Light'), local('NotoSansKR-Light'),
  url('../assetsNew/fonts/noto-sans-kr-v9-latin_korean-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../assetsNew/fonts/noto-sans-kr-v9-latin_korean-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('../assetsNew/fonts/noto-sans-kr-v9-latin_korean-300.woff') format('woff'), /* Modern Browsers */
  url('../assetsNew/fonts/noto-sans-kr-v9-latin_korean-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../assetsNew/fonts/noto-sans-kr-v9-latin_korean-300.svg#NotoSansKR') format('svg'); /* Legacy iOS */
}
/* noto-sans-kr-regular - latin_korean */
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  src: url('../assetsNew/fonts/noto-sans-kr-v9-latin_korean-regular.eot'); /* IE9 Compat Modes */
  src: local('Noto Sans KR Regular'), local('NotoSansKR-Regular'),
  url('../assetsNew/fonts/noto-sans-kr-v9-latin_korean-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../assetsNew/fonts/noto-sans-kr-v9-latin_korean-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../assetsNew/fonts/noto-sans-kr-v9-latin_korean-regular.woff') format('woff'), /* Modern Browsers */
  url('../assetsNew/fonts/noto-sans-kr-v9-latin_korean-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../assetsNew/fonts/noto-sans-kr-v9-latin_korean-regular.svg#NotoSansKR') format('svg'); /* Legacy iOS */
}
/* noto-sans-kr-500 - latin_korean */
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 500;
  src: url('../assetsNew/fonts/noto-sans-kr-v9-latin_korean-500.eot'); /* IE9 Compat Modes */
  src: local('Noto Sans KR Medium'), local('NotoSansKR-Medium'),
  url('../assetsNew/fonts/noto-sans-kr-v9-latin_korean-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../assetsNew/fonts/noto-sans-kr-v9-latin_korean-500.woff2') format('woff2'), /* Super Modern Browsers */
  url('../assetsNew/fonts/noto-sans-kr-v9-latin_korean-500.woff') format('woff'), /* Modern Browsers */
  url('../assetsNew/fonts/noto-sans-kr-v9-latin_korean-500.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../assetsNew/fonts/noto-sans-kr-v9-latin_korean-500.svg#NotoSansKR') format('svg'); /* Legacy iOS */
}
/* noto-sans-kr-700 - latin_korean */
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  src: url('../assetsNew/fonts/noto-sans-kr-v9-latin_korean-700.eot'); /* IE9 Compat Modes */
  src: local('Noto Sans KR Bold'), local('NotoSansKR-Bold'),
  url('../assetsNew/fonts/noto-sans-kr-v9-latin_korean-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../assetsNew/fonts/noto-sans-kr-v9-latin_korean-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('../assetsNew/fonts/noto-sans-kr-v9-latin_korean-700.woff') format('woff'), /* Modern Browsers */
  url('../assetsNew/fonts/noto-sans-kr-v9-latin_korean-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../assetsNew/fonts/noto-sans-kr-v9-latin_korean-700.svg#NotoSansKR') format('svg'); /* Legacy iOS */
}
/* noto-sans-kr-900 - latin_korean */
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 900;
  src: url('../assetsNew/fonts/noto-sans-kr-v9-latin_korean-900.eot'); /* IE9 Compat Modes */
  src: local('Noto Sans KR Black'), local('NotoSansKR-Black'),
  url('../assetsNew/fonts/noto-sans-kr-v9-latin_korean-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../assetsNew/fonts/noto-sans-kr-v9-latin_korean-900.woff2') format('woff2'), /* Super Modern Browsers */
  url('../assetsNew/fonts/noto-sans-kr-v9-latin_korean-900.woff') format('woff'), /* Modern Browsers */
  url('../assetsNew/fonts/noto-sans-kr-v9-latin_korean-900.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../assetsNew/fonts/noto-sans-kr-v9-latin_korean-900.svg#NotoSansKR') format('svg'); /* Legacy iOS */
}

/* *{ font-family:'Noto Sans KR', sans-serif;font-weight:400;font-size:14px;color:#666;-webkit-overflow-scrolling: touch;letter-spacing:-.5px !important;box-sizing: initial;} */
.container html,body,p, input, select, form, label, mark, ul, ul li, ol, ol li, dl, dl dt, dl dd, img, a, table, h1, h2, h3, h4, h5{margin:0;padding:0;line-height: initial}
.container img{ border:0; }
.container ul, ol{ list-style:none;}
.container a{color:#555;text-decoration:none;}
.container a:hover, a:active, a:visited{text-decoration:underline; }
.container table{border:0;border-collapse:collapse;table-layout:fixed;}

.hidden{position:absolute;left:-20000px;}
.w100{width:100px !important;}
.w150{width:150px !important;}

.ml5{margin-left:5px !important}
.ml20{margin-left:20px !important}
.ml30{margin-left:30px !important}

.mt0{margin-top:0px !important}
.mt5{margin-top:5px !important}
.mt10{margin-top:10px !important}
.mt20{margin-top:20px !important}
.mt30{margin-top:30px !important}
.mt35{margin-top:35px !important}
.mt40{margin-top:40px !important}
.mt50{margin-top:50px !important}

.mb5{margin-bottom:5px !important}
.mb10{margin-bottom:10px;}
.mb20{margin-bottom:20px;}
.mb30{margin-bottom:30px;}
/**/
/* ::selection {background:#833f9e;color: #FFF;text-shadow: none;}
::-moz-selection {background:#833f9e;color: #FFF;text-shadow: none;}
::-webkit-selection {background:#833f9e;color: #FFF;text-shadow: none;} */

a{opacity:1;text-decoration:none !important;}

a.cubic{-webkit-transition:.3s;outline:0;}
a.cubic:active{outline:0;}
a.cubic:hover{opacity:.8;-webkit-transform: scale(0.95);-webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.47, 2.02);}

.hidden{position:absolute;left:-20000px;}

select {cursor:pointer;outline:none;background:#fff url(../assetsNew/image/bg/bg-select01.png) no-repeat right center;appearance: none;-moz-appearance:none;-webkit-appearance: none;}
select::-ms-expand {display:none}

input[type=checkbox]{cursor:pointer;outline:none;float:left;background:url(../assetsNew/image/bg/bg-chk-off.svg) no-repeat center center;border:1px solid #5fb0b5;width:18px;height:18px;border-radius:5px;overflow:hidden;appearance: none;-moz-appearance: none;-webkit-appearance: none;}
input[type=checkbox]:checked{width:18px;height:18px;background:url(../assetsNew/image/bg/bg-chk-on.svg) no-repeat center center;}
input[type=checkbox]::-ms-check{border:0;outline:none;appearance: none;-moz-appearance: none;-webkit-appearance: none;width:18px;height:18px;content:"";background:url(../assetsNew/image/bg/bg-chk-off.svg) no-repeat center center;}
input[type=checkbox]:checked::-ms-check{color:transparent;background:url(../assetsNew/image/bg/bg-chk-on.svg) no-repeat center center;}

input{outline:none;}
button{cursor:pointer;outline:none;}
input::-webkit-input-placeholder {color: #c7c7cc;}
textarea::-webkit-input-placeholder {color: #c7c7cc;}

/* *{box-sizing: initial;} */


.servicePageWrap{box-sizing: initial;
  width:80vw;
    height:auto;margin:0px auto 0 auto;display:flex;justify-content:space-between;align-items:flex-start;box-sizing: initial;}