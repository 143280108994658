
/*매뉴얼 팝업*/
.wrapper.ptype{background:#fff; box-sizing: initial;width: 80%;}
.wrapper .popHeader{box-sizing: initial;width:calc( 100% - 40px );height:60px;overflow:hidden;margin:0 auto;display:flex;justify-content:space-between;align-items:center;}
.wrapper .popHeader .ptit{display:flex;justify-content:flex-start;align-items:center;}
.wrapper .popHeader .ptit img{display:flex;}
.wrapper .popHeader .ptit strong{display:flex;margin-left:10px;font-size:20px;color:#111;font-weight:500;}
.wrapper .popHeader .plogo{box-sizing: initial;width:131px;}
.wrapper .popHeader .plogo img{box-sizing: initial;width:100%;float:left;}
.wrapper .popCont{box-sizing: initial;width:calc( 100% - 42px );height:auto;overflow:hidden;border:1px solid #9be1e3;background:#ecf2f2;margin:0 auto;}
.wrapper .popCont ul{box-sizing: initial;width:calc( 100% - 40px );margin:0 auto 20px auto;height:auto;overflow:hidden;}
.wrapper .popCont ul li{box-sizing: initial;width:calc( 100% - 2px );height:44px;border:1px solid #06b3ba;overflow:hidden;margin-top:20px;display:flex;justify-content:flex-start;align-items:center;background:#fff;}
.wrapper .popCont ul li .key{box-sizing: initial;width:157px;padding-left:20px;height:44px;overflow:hidden;background:#06b3ba;display:flex;justify-content:flex-start;align-items:center;font-size:16px;color:#fff;font-weight:500;}
.wrapper .popCont ul li .value{box-sizing: initial;width:100%;flex:1;height:44px;overflow:hidden;display:flex;justify-content:space-between;align-items:center;}
.wrapper .popCont ul li .value .fileInfo{margin-left:20px;display:flex;justify-content:flex-start;align-items:center;}
.wrapper .popCont ul li .value .fileInfo img{display:flex;}
.wrapper .popCont ul li .value .fileInfo strong{display:flex;margin-left:10px;font-size:14px;color:#333;font-weight:500;}
.wrapper .popCont ul li .value .fileInfo span{display:flex;margin-left:13px;font-size:14px;color:#999;}
.wrapper .popCont ul li .value button{display:flex;justify-content:center;align-items:center;box-sizing: initial;width:118px;height:30px;overflow:hidden;border-radius:8px;border:0;background:#06b3ba;margin-right:7px;}
.wrapper .popCont ul li .value button img{display:flex;}
.wrapper .popCont ul li .value button span{display:flex;margin-left:10px;font-size:14px;color:#fff;font-weight:500;}
.wrapper .popCont ul li .value button:hover{background:#055053;}
.wrapper .popBtnWrap{box-sizing: initial;width:calc( 100% - 60px );margin:20px auto;height:auto;overflow:hidden;display:flex;justify-content:flex-end;align-items:center;}
.wrapper .popBtnWrap button{box-sizing: initial;width:100px;height:30px;overflow:hidden;border:0;border-radius:15px;background:#06b3ba;display:flex;justify-content:center;align-items:center;font-size:16px;color:#fff;font-weight:500;padding:0;}
