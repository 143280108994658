
/*서비스 선사*/
.subHeaderWrap{box-sizing: initial;
    /* width:80vw; */
    height:29px;overflow:hidden;
    /* margin:26px auto 0 auto; */
    margin:26px auto;
    display:flex;justify-content:space-between;align-items:center;}
.subHeaderWrap .stit{display:flex;justify-content:flex-start;align-items:center;}
.subHeaderWrap .stit img{display:flex;}
.subHeaderWrap .stit strong{display:flex;font-size:20px;color:#111;font-weight:500;margin-left:10px;}
.subHeaderWrap ul{display:flex;justify-content:flex-start;align-items:center;margin: 0;}
.subHeaderWrap ul li{background:url(../../assetsNew/image/bg/bg-loc-arr.png) no-repeat 10px center;display:flex;justify-content:flex-start;align-items:center;padding-left:28px;position:relative;font-size:15px;color:#666;}
.subHeaderWrap ul li:nth-child(1){background:transparent; cursor: pointer;}
/* .subHeaderWrap ul li:nth-child(2) */
/* .subHeaderWrap ul li:nth-child(2){background:transparent;} */
.subHeaderWrap ul li strong{font-size:15px;color:#06b3ba;font-weight:500;}

.sunsaPageWrap{background:#fff;box-sizing: initial;max-width:1338px;height:auto;overflow:hidden;border:1px solid #06b3ba;margin:8px auto 50px auto;}
.sunsaPageWrap .tabmenuListWrap{box-sizing: initial;width:100%;height:43px;overflow:hidden;display:flex;justify-content:flex-start;align-items:center;}
.sunsaPageWrap .tabmenuListWrap li{box-sizing: initial;width:100%;height:43px;overflow:hidden;display:flex;justify-content:center;align-items:center;flex:1;cursor: pointer;}
.sunsaPageWrap .tabmenuListWrap .tab{position:relative;box-sizing: initial;width:100%;height:43px;overflow:hidden;display:flex;justify-content:center;align-items:center;flex:1;font-size:15px;color:#222;font-weight:500;}
.sunsaPageWrap .tabmenuListWrap li:before{content:"";box-sizing: initial;width:1px;height:100%;overflow:hidden;display:block;background:#d5dbdb;position:absolute;left:0;top:0;}
.sunsaPageWrap .tabmenuListWrap li:after{content:"";box-sizing: initial;width:100%;height:1px;overflow:hidden;display:block;background:#d5dbdb;position:absolute;left:0;bottom:0;}
.sunsaPageWrap .tabmenuListWrap li:first-child:before{display:none;}
.sunsaPageWrap .tabmenuListWrap li:first-child:after{}
.sunsaPageWrap .tabmenuListWrap li:hover{background:#c3e5e6;}
/* .sunsaPageWrap .tabmenuListWr li:last-child a{background:#c3e5e6;} */
.sunsaPageWrap .tabmenuListWrap .tab.on{background:#06b3ba;color:#fff;}
.sunsaPageWrap .tabmenuListWrap .tab.on:before{background:#06b3ba;}
.sunsaPageWrap .tabmenuListWrap .tab.on:after{background:#06b3ba;}
.sunsaPageWrap .sunsaHeaderWrap{box-sizing: initial;width:calc( 100% - 60px );height:auto;overflow:hidden;margin:30px auto 0 auto;display:flex;justify-content:space-between;align-items:center;}
.sunsaPageWrap .sunsaHeaderWrap .mtit{display:flex;font-size:20px;color:#111;font-weight:500;}
.sunsaPageWrap .sunsaHeaderWrap .searchItemWrap{display:flex;justify-content:flex-start;align-items:center;}
.sunsaPageWrap .sunsaHeaderWrap .searchItemWrap .inp{box-sizing: initial;width:calc( 213px - 2px -12px );padding-left:12px;height:34px;overflow:hidden;border:1px solid #5fb0b4;border-radius:8px;font-size:14px;color:#111;font-weight:500;}
.sunsaPageWrap .sunsaHeaderWrap .searchItemWrap button{margin-left:6px;box-sizing: initial;width:38px;height:36px;overflow:hidden;border:0;background:transparent;position:relative;padding:0;}
.sunsaPageWrap .sunsaHeaderWrap .searchItemWrap button:hover:before{content:"";position:absolute;left:0;top:0;box-sizing: initial;width:38px;height:36px;display:block;background:url(../../assetsNew/image/ico/ico-search-on.png) no-repeat;}


.sunsaPageWrap .scrolls{box-sizing: initial;width:calc( 100% - 30px );height:440px;overflow:hidden;margin:30px auto 30px auto;overflow-y:auto;}
.sunsaPageWrap .sunsaHeaderWrap + .scrolls{margin-top:10px;}
.sunsaPageWrap .scrolls::-webkit-scrollbar {box-sizing: initial;width: 5px;}
.sunsaPageWrap .scrolls::-webkit-scrollbar-thumb {background-color: #73c8cb;border-radius:3px;}
.sunsaPageWrap .scrolls::-webkit-scrollbar-track {box-sizing: initial;width:0px;background-color: transparent;}
.sunsaPageWrap .scrolls .bizListWrap{box-sizing: initial;width:calc( 100% - 10px );margin-left:5px;height:auto;overflow:hidden;cursor: pointer;}
.sunsaPageWrap .scrolls .bizListWrap li{box-sizing: initial;width:calc( 25% - 20px );height:66px;overflow:hidden;margin:5px 10px;float:left;}
.sunsaPageWrap .scrolls .bizListWrap li div{box-sizing: initial;width:calc( 100% - 2px );height:64px;overflow:hidden;border-radius:8px;display:flex;justify-content:center;align-items:center;border:1px solid #5fb0b5;}
.sunsaPageWrap .scrolls .bizListWrap li div{box-sizing: initial;width:calc( 100% - 2px );height:64px;overflow:hidden;border-radius:8px;display:flex;justify-content:flex-start;align-items:center;border:1px solid #5fb0b5;}
/* .sunsaPageWrap .scrolls .bizListWrap li div{box-sizing: initial;width:calc( 100% - 42px );height:64px;overflow:hidden;border-radius:8px;display:flex;justify-content:flex-start;align-items:center;border:1px solid #5fb0b5; padding-left: 40px;} */
.sunsaPageWrap .scrolls .bizListWrap li div img{display:flex;margin-left: 40px;}
.sunsaPageWrap .scrolls .bizListWrap li div span{display:flex;margin-left:10px;font-size:20px;color:#333;font-weight:500;}
.sunsaPageWrap .scrolls .bizListWrap li div.on{box-sizing: initial;width:calc( 100% - 10px );height:56px;border:5px solid #5fb0b5;}
.sunsaPageWrap .scrolls .bizListWrap li div:hover{box-sizing: initial;width:calc( 100% - 10px );height:56px;border:5px solid #5fb0b5;}

.sunsaPageWrap .scrolls table{box-sizing: initial;width:calc( 100% - 30px );margin:0 auto;table-layout:fixed;border:0;border-collapse:collapse;}
.sunsaPageWrap .scrolls table th{border:1px solid #b9bdbd;border-top:2px solid #06b3ba;height:34px;text-align:center;background:#eff2f2;font-size:14px;color:#111;font-weight:500;}
.sunsaPageWrap .scrolls table td{text-align:center;line-height:1.5em;border:1px solid #d9dcdc;padding:13px;font-size:14px;color:#111; word-break: keep-all;}
.sunsaPageWrap .scrolls table td strong{font-size:14px;color:#111;font-weight:500;display:block;text-align:center;}
.sunsaPageWrap .scrolls table td strong.str{display:inline;}
.sunsaPageWrap .scrolls .nlist{box-sizing: initial;width:calc( 100% - 30px );height:auto;overflow:hidden;margin:20px auto 0 auto;}
.sunsaPageWrap .scrolls .nlist li{box-sizing: initial;width:calc( 100% - 10px );height:auto;overflow:hidden;padding-left:10px;line-height:1.5em;font-size:14px;color:#666;}
.sunsaPageWrap .scrolls .nlist li a{color: #007bff;}

/*이용신청서*/
.sunsaPageWrap .scrolls .serviceReqWrap{box-sizing: initial;width:calc( 100% - 30px );height:auto;overflow:hidden;margin:0 auto;display:flex;justify-content:space-between;align-items:flex-start;}
.sunsaPageWrap .scrolls .serviceReqWrap .lt{box-sizing: initial;width:700px;height:auto;overflow:hidden;}
.sunsaPageWrap .scrolls .serviceReqWrap .lt img{width: 90%;}
.sunsaPageWrap .scrolls .serviceReqWrap .rt{margin-left:30px;box-sizing: initial;width:100%;flex:1;height:auto;overflow:hidden;}
.sunsaPageWrap .scrolls .serviceReqWrap .rt dl{box-sizing: initial;width:100%;height:auto;overflow:hidden;}
.sunsaPageWrap .scrolls .serviceReqWrap .rt dl dt{margin-top:10px;box-sizing: initial;width:100%;height:auto;overflow:hidden;display:flex;justify-content:flex-start;align-items:center;}
.sunsaPageWrap .scrolls .serviceReqWrap .rt dl dt img{display:flex;}
.sunsaPageWrap .scrolls .serviceReqWrap .rt dl dt strong{display:flex;font-size:16px;color:#069ea4;font-weight:500;margin-left:10px;}
.sunsaPageWrap .scrolls .serviceReqWrap .rt dl dd{box-sizing: initial;width:100%;height:auto;overflow:hidden;}
.sunsaPageWrap .scrolls .serviceReqWrap .rt dl dd ul{box-sizing: initial;width:100%;height:auto;overflow:hidden;}
.sunsaPageWrap .scrolls .serviceReqWrap .rt dl dd ul li{box-sizing: initial;width:100%;height:auto;overflow:hidden;font-size:16px;color:#222;margin-top:20px;}
.sunsaPageWrap .scrolls .serviceReqWrap .rt button{margin-top:20px;box-sizing: initial;width:260px;border-radius:20px;border:0;background:#06b3ba;height:40px;overflow:hidden;display:flex;justify-content:center;align-items:center;}
.sunsaPageWrap .scrolls .serviceReqWrap .rt button span{display:flex;font-size:16px;color:#fff;font-weight:500;}
.sunsaPageWrap .scrolls .serviceReqWrap .rt button img{display:flex;margin-left:10px; }


.sunsaPageWrap .scrolls .serviceReqWrap .rt .btn{width:400px}
.sunsaPageWrap .scrolls .serviceReqWrap .lt .img{display:flex; width:90%}
