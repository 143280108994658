/*FOOTER*/
.footer{box-sizing: initial;width:100%;height:auto;overflow:hidden;background:#3f3f3f;font-size:14px;}

/************************
.footer .fnbItemWrap 반영 유의
운영 색상 background:#2b2b2b;
개발 색상 background:#88b749; 
개발oracle 색상 background:#f75c5c; 
*************************/
.footer .fnbItemWrap{box-sizing: initial;width:100%;height:52px;overflow:hidden;background:#2b2b2b;}
.footer .fnbItemWrap ul{box-sizing: initial;width:1340px;height:52px;overflow:Hidden;margin:0 auto;display:flex;justify-content:flex-start;align-items:center;}
.footer .fnbItemWrap ul li{display:flex;margin-right:50px;}
.footer .fnbItemWrap ul li .anchor{font-size:14px;color:#fff;font-weight:500;cursor:pointer;}
.footer .fnbItemWrap ul li .anchor:hover{color:#01b3ba;}
.footer .rela{box-sizing: initial;width:1340px;height:auto;overflow:Hidden;margin:0 auto;padding:50px 0;display:flex;justify-content:flex-start;align-items:center;}
.footer .rela .flogo{box-sizing: initial;width:280px;height:auto;overflow:Hidden;}
.footer .rela .fcont{box-sizing: initial;width:100%;height:auto;overflow:hidden;flex:1;}
.footer .addr{box-sizing: initial;width:100%;height:auto;overflow:hidden;font-size:14px;color:#fff;font-weight:500;line-height:1.8em;}
.footer .copy{box-sizing: initial;width:100%;height:auto;overflow:hidden;font-size:14px;color:#fff;font-weight:500;line-height:1.8em;}
