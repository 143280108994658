// ============================================================================
// COLORS
// ============================================================================

$mui-primary-color: mui-color('blue', '500') !default;
$mui-primary-color-dark: mui-color('blue', '700') !default;
$mui-primary-color-light: mui-color('blue', '100') !default;

$mui-accent-color: mui-color('pink', 'A200') !default;
$mui-accent-color-dark: mui-color('pink', 'A400') !default;
$mui-accent-color-light: mui-color('pink', 'A100') !default;

$mui-danger-color: mui-color('red', '500') !default;

// ============================================================================
// GLOBAL VARIABLES
// ============================================================================

$mui-include-globals: true !default;

$mui-body-bg-color: mui-color('white') !default;

$mui-base-font-color: mui-color('black-alpha-87') !default;
$mui-base-font-family: Arial, Verdana, Tahoma !default;
$mui-base-font-size: 14px !default;
$mui-base-font-weight: 400 !default;
$mui-base-line-height: 1.429 !default;
$mui-base-font-smoothing: false !default;

$mui-link-font-color: $mui-primary-color !default;
$mui-link-text-decoration: none !default;
$mui-link-font-color-hover: $mui-primary-color-dark !default;
$mui-link-text-decoration-hover: underline !default;

$mui-cursor-disabled: not-allowed !default;
$mui-border-radius-default: 2px !default;

$mui-hr-color: mui-color('black-alpha-12') !default;
$mui-abbr-border-color: $mui-primary-color !default;

// ============================================================================
// MEDIA QUERY BREAKPOINTS
// ============================================================================

$mui-screen-sm-min: 544px !default;
$mui-screen-md-min: 768px !default;
$mui-screen-lg-min: 992px !default;
$mui-screen-xl-min: 1200px !default;

// ============================================================================
// GRID SYSTEM
// ============================================================================

$mui-grid-columns: 12 !default;
$mui-grid-gutter-width: 30px !default;
$mui-grid-float-breakpoint: $mui-screen-sm-min !default;

// ============================================================================
// CONTAINER SIZES
// ============================================================================

$mui-container-sm: 570px !default;
$mui-container-md: 740px !default;
$mui-container-lg: 960px !default;
$mui-container-xl: 1170px !default;

// ============================================================================
// TEXT COLORS
// ============================================================================

$mui-text-dark: mui-color('black-alpha-87') !default;
$mui-text-dark-secondary: mui-color('black-alpha-54') !default;
$mui-text-dark-hint: mui-color('black-alpha-38') !default;

$mui-text-light: mui-color('white') !default;
$mui-text-light-secondary: mui-color('white-alpha-70') !default;
$mui-text-light-hint: mui-color('white-alpha-30') !default;

$mui-text-accent: rgba($mui-accent-color, 0.87) !default;
$mui-text-accent-secondary: rgba($mui-accent-color, 0.54) !default;
$mui-text-accent-hint: rgba($mui-accent-color, 0.38) !default;

$mui-text-danger: $mui-danger-color !default;

// ============================================================================
// BUTTONS
// ============================================================================

$mui-btn-height: 36px !default;
$mui-btn-height-sm: $mui-btn-height * 0.85 !default;
$mui-btn-height-lg: $mui-btn-height * 1.5 !default;

$mui-btn-border-radius: $mui-border-radius-default !default;

$mui-btn-padding-horizontal: 26px !default;
$mui-btn-padding-horizontal-sm: 16px !default;
$mui-btn-padding-horizontal-lg: $mui-btn-padding-horizontal !default;

$mui-btn-font-size: 14px !default;
$mui-btn-font-size-sm: 13px !default;
$mui-btn-font-size-lg: $mui-btn-font-size !default;

$mui-btn-fab-diameter: 55px !default;
$mui-btn-fab-diameter-sm: 44px !default;
$mui-btn-fab-diameter-lg: 75px !default;

$mui-btn-margin-vertical: 6px !default;
$mui-btn-spacing-horizontal: 8px !default;

// ----------------------------------------------------------------------------
// COLORS
// ----------------------------------------------------------------------------

$mui-btn-default-bg-color: mui-color('white') !default;
$mui-btn-default-font-color: $mui-base-font-color !default;
$mui-btn-default-ripple-color: darken(mui-color('white'), 35%) !default;

$mui-btn-primary-bg-color: $mui-primary-color !default;
$mui-btn-primary-font-color: mui-color('white') !default;
$mui-btn-primary-ripple-color: mui-color('white') !default;

$mui-btn-danger-bg-color: $mui-danger-color !default;
$mui-btn-danger-font-color: mui-color('white') !default;
$mui-btn-danger-ripple-color: mui-color('white') !default;

$mui-btn-dark-bg-color: mui-color('grey', '800') !default;
$mui-btn-dark-font-color: mui-color('white') !default;
$mui-btn-dark-ripple-color: mui-color('white') !default;

$mui-btn-accent-bg-color: $mui-accent-color !default;
$mui-btn-accent-font-color: mui-color('white') !default;
$mui-btn-accent-ripple-color: mui-color('white') !default;

$mui-btn-flat-bg-color: transparent !default;
$mui-btn-flat-bg-color-hover: darken(mui-color('white'), 5%) !default;
$mui-btn-flat-ripple-color: darken(mui-color('white'), 35%) !default;

// ============================================================================
// FORMS
// ============================================================================

$mui-input-bg-color: transparent !default;
$mui-input-bg-color-disabled: transparent !default;
$mui-input-font-color: $mui-base-font-color !default;
$mui-input-font-size: 16px !default;
$mui-input-height: 32px !default;

$mui-input-placeholder-color: rgba(mui-color('black'), 0.26) !default;

$mui-input-border-color: $mui-input-placeholder-color !default;
$mui-input-border-color-focus: $mui-primary-color !default;

$mui-textarea-height: 64px !default;

$mui-label-font-size: 12px !default;
$mui-label-font-color: mui-color('black-alpha-54') !default;
$mui-label-margin-bottom: 3px !default;

$mui-form-legend-font-size: $mui-base-font-size * 1.5 !default;
$mui-form-legend-margin-bottom: $mui-form-legend-font-size / 2 !default;
$mui-form-legend-font-color: $mui-base-font-color !default;
$mui-form-group-margin-bottom: 20px !default;

// ============================================================================
// SELECT
// ============================================================================

$mui-select-menu-font-size: $mui-input-font-size !default;
$mui-select-menu-bg-color: mui-color('white') !default;
$mui-select-menu-item-height: $mui-select-menu-font-size + 26px !default;
$mui-select-menu-item-bg-color-selected: mui-color('grey', '200') !default;
$mui-select-menu-item-bg-color-hover: mui-color('grey', '300') !default;

// ============================================================================
// PANELS
// ============================================================================

$mui-panel-bg-color: mui-color('white') !default;
$mui-panel-padding: 15px !default;
$mui-panel-border-radius: 0 !default;

// ============================================================================
// DIVIDERS
// ============================================================================

$mui-divider-color: mui-color('black-alpha-12') !default;

// ============================================================================
// DROPDOWNS
// ============================================================================

$mui-dropdown-bg-color: mui-color('white') !default;
$mui-dropdown-border-color: $mui-divider-color !default;
$mui-dropdown-border-radius: $mui-border-radius-default !default;

$mui-dropdown-link-font-color: $mui-base-font-color !default;
$mui-dropdown-link-font-color-hover: darken(
  $mui-dropdown-link-font-color,
  5%
) !default;
$mui-dropdown-link-font-color-active: $mui-accent-color !default;
$mui-dropdown-link-font-color-disabled: mui-color('grey', '200') !default;

$mui-dropdown-link-bg-color-hover: mui-color('grey', '200') !default;
$mui-dropdown-link-bg-color-active: mui-color('white') !default;

// ============================================================================
// APPBAR
// ============================================================================

$mui-appbar-height: 64px !default;
$mui-appbar-height-xs-portrait: 56px !default;
$mui-appbar-height-xs-landscape: 48px !default;

$mui-appbar-bg-color: $mui-primary-color !default;
$mui-appbar-font-color: mui-color('white') !default;

// ============================================================================
// TABLES
// ============================================================================

$mui-table-cell-padding: 10px !default;
$mui-table-border-color: $mui-divider-color !default;

// ============================================================================
// TABS
// ============================================================================

$mui-tab-font-color: $mui-base-font-color !default;
$mui-tab-font-color-active: $mui-primary-color !default;
$mui-tab-border-color-active: $mui-primary-color !default;

// ============================================================================
// CARET
// ============================================================================

$mui-caret-width: 4px !default;

// ============================================================================
// OVERLAY
// ============================================================================

$mui-overlay-bg-color: rgba(mui-color('black'), 0.2) !default;

// ============================================================================
// INTERNAL VARIABLES
// ============================================================================

$mui-base-line-height-computed: floor(
  ($mui-base-font-size * $mui-base-line-height)
);
$mui-screen-xs-max: ($mui-screen-sm-min - 1);
$mui-screen-sm-max: ($mui-screen-md-min - 1);
$mui-screen-md-max: ($mui-screen-lg-min - 1);
$mui-screen-lg-max: ($mui-screen-xl-min - 1);
