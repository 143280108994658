/**
 * MUI Appbar
 */

$x-mobile-breakpoint: 480px;

// ============================================================================
// HELPER CLASSES
// ============================================================================

// mobile portrait
.mui--appbar-height {
  height: $mui-appbar-height-xs-portrait;
}
.mui--appbar-min-height {
  min-height: $mui-appbar-height-xs-portrait;
}
.mui--appbar-line-height {
  line-height: $mui-appbar-height-xs-portrait;
}
.mui--appbar-top {
  top: $mui-appbar-height-xs-portrait;
}

// mobile landscape
@media (orientation: landscape) and (max-height: $x-mobile-breakpoint) {
  .mui--appbar-height {
    height: $mui-appbar-height-xs-landscape;
  }
  .mui--appbar-min-height {
    min-height: $mui-appbar-height-xs-landscape;
  }
  .mui--appbar-line-height {
    line-height: $mui-appbar-height-xs-landscape;
  }
  .mui--appbar-top {
    top: $mui-appbar-height-xs-landscape;
  }
}

// all other screen sizes
@media (min-width: $x-mobile-breakpoint) {
  .mui--appbar-height {
    height: $mui-appbar-height;
  }
  .mui--appbar-min-height {
    min-height: $mui-appbar-height;
  }
  .mui--appbar-line-height {
    line-height: $mui-appbar-height;
  }
  .mui--appbar-top {
    top: $mui-appbar-height;
  }
}

// ============================================================================
// APPBAR
// ============================================================================
.mui-appbar {
  @extend .mui--appbar-min-height;

  background-color: $mui-appbar-bg-color;
  color: $mui-appbar-font-color;
}
