/**
 * MUI Form Component
 */

.mui-form {
  legend {
    display: block;
    width: 100%;
    padding: 0;
    margin-bottom: $mui-base-line-height-computed / 2;
    font-size: $mui-form-legend-font-size;
    color: $mui-form-legend-font-color;
    line-height: inherit;
    border: 0;
  }

  fieldset {
    border: 0;
    padding: 0;
    margin: 0 0 $mui-form-group-margin-bottom 0;
  }
}

.mui-form--inline {
  @media (min-width: $mui-screen-sm-min) {
    .mui-textfield {
      display: inline-block;
      vertical-align: bottom;
      margin-bottom: 0;
    }

    .mui-radio,
    .mui-checkbox {
      display: inline-block;
      margin-top: 0;
      margin-bottom: 0;
      vertical-align: middle;

      > label {
        padding-left: 0;
      }
    }

    .mui-radio > label > input[type='radio'],
    .mui-checkbox > label > input[type='checkbox'] {
      position: relative;
      margin-left: 0;
    }

    .mui-select {
      display: inline-block;
      vertical-align: bottom;
      margin-bottom: 0;
    }

    .mui-btn {
      margin-bottom: 0;
      margin-top: 0;
      vertical-align: bottom;
    }
  }
}
