
/*메일 알림설정 팝업*/
.ptype{background:#fff; box-sizing: initial;width: 100%;}
.pHeader{box-sizing: initial;width:calc( 100% - 40px );height:60px;overflow:hidden;margin:0 auto;display:flex;justify-content:space-between;align-items:center;}
.pHeader .ptit{display:flex;justify-content:flex-start;align-items:center;}
.pHeader .ptit strong{display:flex;font-size:20px;color:#111;font-weight:500;}
.pCont{box-sizing: initial;width:calc( 100% - 22px );height:auto;overflow:hidden;
    /* border:1px solid #9be1e3;background:#ecf2f2; */
    margin:0 auto;}
.pwrap{margin:0 10px;}


/*메일 알림동의 팝업*/
.popHeader{box-sizing: initial;width:calc( 100% - 40px );height:60px;overflow:hidden;margin:0 auto;display:flex;justify-content:center;align-items:center;}
.popHeader .ptit{display:flex;justify-content:flex-start;align-items:center;}
.popHeader .ptit img{display:flex;}
.popHeader .ptit strong{display:flex;margin-left:10px;font-size:32px;color:#111;font-weight:500;}
.popHeader .ptit p{display:flex;font-size:16px;color:#00b82e;font-weight:500;}

.popHeader .plogo{box-sizing: initial;width:131px;margin-right:12px;}
.popHeader .plogo img{box-sizing: initial;width:100%;float:left;}
.popCont{box-sizing: initial;width:calc( 100% - 42px );height:auto;overflow:hidden;border:1px solid #9be1e3;background:#ecf2f2;margin:0 auto;}
.popCont {box-sizing: initial;width:calc( 100% - 40px );margin:0 auto 20px auto;height:auto;overflow:hidden;}
.popCont {box-sizing: initial;/*width:calc( 100% - 2px );margin-top:20px;*/
    border:1px solid #06b3ba;overflow:hidden;
    display:flex;justify-content:center;align-items:center;background:#fff;}
.popCont .key{box-sizing: initial;width:157px;padding-left:20px;overflow:hidden;background:#06b3ba;display:flex;justify-content:flex-start;align-items:center;font-size:16px;color:#fff;font-weight:500;}
.popCont .value{box-sizing: initial;width:100%;flex:1;height:44px;overflow:hidden;display:flex;justify-content:space-between;align-items:center;}
.popCont .value .fileInfo{margin-left:20px;display:flex;justify-content:flex-start;align-items:center;}
.popCont .value .fileInfo img{display:flex;}
.popCont .value .fileInfo strong{display:flex;margin-left:10px;font-size:14px;color:#333;font-weight:500;}
.popCont .value .fileInfo span{display:flex;margin-left:13px;font-size:14px;color:#999;}
.popCont .value button{display:flex;justify-content:center;align-items:center;box-sizing: initial;width:118px;height:30px;overflow:hidden;border-radius:8px;border:0;background:#06b3ba;margin-right:7px;}
.popCont .value button img{display:flex;}
.popCont .value button span{display:flex;margin-left:10px;font-size:14px;color:#fff;font-weight:500;}
.popCont .value button:hover{background:#055053;}

.popContBtnWrap{box-sizing: initial;width:calc( 100% - 40px );margin:15px auto;height:auto;overflow:hidden;display:flex;justify-content:center;align-items:center;display: flex;flex-direction: column;}
.popContBtnWrap .rtbutton{box-sizing: initial;width:180px;border-radius:20px;border:0;background:#06b3ba;height:35px;overflow:hidden;display:flex;justify-content:center;align-items:center;}

.popBtnWrap{box-sizing: initial;width:50%;margin:20px auto;height:auto;overflow:hidden;display:flex;justify-content:space-evenly;align-items:center;}
.popBtnWrap .rtbutton{box-sizing: initial;width:90px;border-radius:10px;border:0;background:#06b3ba;height:35px;overflow:hidden;display:flex;justify-content:center;align-items:center;}
/* .popBtnWrapbutton{box-sizing: initial;width:100px;height:30px;overflow:hidden;border:0;border-radius:15px;background:#06b3ba;display:flex;justify-content:center;align-items:center;font-size:16px;color:#fff;font-weight:500;padding:0;} */

.rtwrap{margin:18px 0px 20px;}
.rtwrap dl{box-sizing: initial;width:100%;height:auto;overflow:hidden;}
.rtwrap dl dt{box-sizing: initial;width:100%;height:auto;overflow:hidden;display:flex;justify-content:center;align-items:center;margin-bottom:10px;margin-top:10px;}
.rtwrap dl dt img{display:flex;}
.rtwrap dl dt strong{display:flex;font-size:16px;color:#069ea4;font-weight:500;margin-left:10px;}
.rtwrap dl dd{box-sizing: initial;width:100%;height:auto;overflow:hidden;}
.rtwrap dl dd ul{box-sizing: initial;width:100%;height:auto;overflow:hidden;}
.rtwrap dl dd ul li{box-sizing: initial;width:100%;height:auto;overflow:hidden;font-size:16px;color:#222;margin-top:5px;word-break: keep-all;display: flex;justify-content: center;}
.rtwrap span{font-weight: 600;}

.rtbutton span{display:flex;font-size:16px;color:#fff;font-weight:500;}
.rtbutton img{display:flex;margin-left:10px; }

/* 
.agreeScrolls {display: flex; width: 100%;}
.agreeScrolls .reqWrap{box-sizing: initial;width:calc( 100% - 30px );height:auto;overflow:hidden;margin:0 auto;}
.agreeScrolls .reqWrap {flex:1;height:auto;overflow:hidden;}
.agreeScrolls .reqWrap .lt{box-sizing: initial;height:auto;overflow:hidden;}
.agreeScrolls .reqWrap .rt{box-sizing: initial;height:auto;overflow:hidden;}
.agreeScrolls .reqWrap .lt img{width: 90%;}
.agreeScrolls .reqWrap dl{box-sizing: initial;width:100%;height:auto;overflow:hidden;}
.agreeScrolls .reqWrap dl dt{margin-top:10px;box-sizing: initial;width:100%;height:auto;overflow:hidden;display:flex;justify-content:flex-start;align-items:center;}
.agreeScrolls .reqWrap dl dt img{display:flex;}
.agreeScrolls .reqWrap dl dt strong{display:flex;font-size:16px;color:#069ea4;font-weight:500;margin-left:10px;}
.agreeScrolls .reqWrap dl dd{box-sizing: initial;width:100%;height:auto;overflow:hidden;}
.agreeScrolls .reqWrap dl dd ul{box-sizing: initial;width:100%;height:auto;overflow:hidden;}
.agreeScrolls .reqWrap dl dd ul li{box-sizing: initial;width:100%;height:auto;overflow:hidden;font-size:16px;color:#222;margin-top:20px;}


.agreeScrolls .reqWrap{box-sizing: initial;width:calc( 100% - 30px );height:auto;overflow:hidden;margin:0 auto;display:flex;justify-content:space-between;align-items:flex-start;}
.agreeScrolls .reqWrap {flex:1;height:auto;overflow:hidden;}
.agreeScrolls .reqWrap .lt{box-sizing: initial;width:49%;height:auto;overflow:hidden;}
.agreeScrolls .reqWrap .rt{box-sizing: initial;width:49%;height:auto;overflow:hidden;}
.agreeScrolls .reqWrap .lt img{width: 90%;}
.agreeScrolls .reqWrap dl{box-sizing: initial;width:100%;height:auto;overflow:hidden;}
.agreeScrolls .reqWrap dl dt{margin-top:10px;box-sizing: initial;width:100%;height:auto;overflow:hidden;display:flex;justify-content:flex-start;align-items:center;}
.agreeScrolls .reqWrap dl dt img{display:flex;}
.agreeScrolls .reqWrap dl dt strong{display:flex;font-size:16px;color:#069ea4;font-weight:500;margin-left:10px;}
.agreeScrolls .reqWrap dl dd{box-sizing: initial;width:100%;height:auto;overflow:hidden;}
.agreeScrolls .reqWrap dl dd ul{box-sizing: initial;width:100%;height:auto;overflow:hidden;}
.agreeScrolls .reqWrap dl dd ul li{box-sizing: initial;width:100%;height:auto;overflow:hidden;font-size:16px;color:#222;margin-top:20px;}
.agreeScrolls .reqWrap button{margin-top:20px;box-sizing: initial;width:260px;border-radius:20px;border:0;background:#06b3ba;height:40px;overflow:hidden;display:flex;justify-content:center;align-items:center;}
.agreeScrolls .reqWrap button span{display:flex;font-size:16px;color:#fff;font-weight:500;}
.agreeScrolls .reqWrap button img{display:flex;margin-left:10px; } */
