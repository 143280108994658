/**
 * MUI Ripple module
 */

.mui-btn__ripple-container {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  height: 100%;
  width: 100%;
  overflow: hidden;
  z-index: 0;
  pointer-events: none;
}

.mui-ripple {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  transform: scale(0.0001, 0.0001);

  &.mui--is-animating {
    transform: none;
    transition: transform 0.3s cubic-bezier(0, 0, 0.2, 1),
      width 0.3s cubic-bezier(0, 0, 0.2, 1),
      height 0.3s cubic-bezier(0, 0, 0.2, 1),
      opacity 0.3s cubic-bezier(0, 0, 0.2, 1);
  }

  &.mui--is-visible {
    opacity: 0.3;
  }
}

.mui-btn .mui-ripple {
  background-color: $mui-btn-default-ripple-color;
}

.mui-btn--primary .mui-ripple {
  background-color: $mui-btn-primary-ripple-color;
}

.mui-btn--dark .mui-ripple {
  background-color: $mui-btn-dark-ripple-color;
}

.mui-btn--danger .mui-ripple {
  background-color: $mui-btn-danger-ripple-color;
}

.mui-btn--accent .mui-ripple {
  background-color: $mui-btn-accent-ripple-color;
}

.mui-btn--flat .mui-ripple {
  background-color: $mui-btn-flat-ripple-color;
}
