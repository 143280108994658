.slick-prev::before,
.slick-next::before{opacity:0; display:none;}
.slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0 auto;
    padding: 0;
    width:  582px;
    /* width:  100%; */
    /* width: fit-content; */
    height: 36px;
}
.slick-track{
    margin-left:0;
    /* left: -23px; */
}

.slick-slide div {
    margin-right:6px;
    /* width: 97%; */
}