// Overrides bootstrap functions to add prfx support

@mixin mui-make-grid-columns(
  $i: 1,
  $list:
    '.mui-col-xs-#{$i}, .mui-col-sm-#{$i}, .mui-col-md-#{$i}, .mui-col-lg-#{$i}'
) {
  @for $i from (1 + 1) through $mui-grid-columns {
    $list: '#{$list}, .mui-col-xs-#{$i}, .mui-col-sm-#{$i}, .mui-col-md-#{$i}, .mui-col-lg-#{$i}';
  }

  #{$list} {
    // box sizing
    box-sizing: border-box;

    // Prevent columns from collapsing when empty
    min-height: 1px;

    // Inner gutter via padding
    padding-left: ($mui-grid-gutter-width / 2);
    padding-right: ($mui-grid-gutter-width / 2);
  }
}

@mixin mui-float-grid-columns(
  $class,
  $i: 1,
  $list: '.mui-col-#{$class}-#{$i}'
) {
  @for $i from (1 + 1) through $mui-grid-columns {
    $list: '#{$list}, .mui-col-#{$class}-#{$i}';
  }

  #{$list} {
    float: left;
  }
}

@mixin mui-calc-grid-column($i, $class, $type) {
  @if ($type == 'width') and ($i > 0) {
    .mui-col-#{$class}-#{$i} {
      width: percentage(($i / $mui-grid-columns));
    }
  }
  @if ($type == 'offset') {
    .mui-col-#{$class}-offset-#{$i} {
      margin-left: percentage(($i / $mui-grid-columns));
    }
  }
}

@mixin mui-loop-grid-columns($columns, $class, $type) {
  @for $i from 0 through $columns {
    @include mui-calc-grid-column($i, $class, $type);
  }
}

@mixin mui-make-grid($class) {
  @include mui-float-grid-columns($class);
  @include mui-loop-grid-columns($mui-grid-columns, $class, 'width');
  @include mui-loop-grid-columns($mui-grid-columns, $class, 'offset');
}
